<template>
  <div class="registrazione">
    <div class="row no-gutters">
      <div class="col-10 offset-1">

        <div class="row no-gutters">
          <div class=" col-12 offset-0">
            <div class="m-card text-center">
              <div class="row no-gutters">
                <div class="col-lg-8 offset-lg-2">
                  <h1 class="o-heading o-heading--xl">
                    {{ $t("fatture_pay.title") }}
                  </h1>
                  <p>{{ $t("fatture_pay.subtitle") }}</p>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters mt-3">
          <div class="col-12 offset-0">
            <b-alert  variant="warning" id="warn_downlaod" v-model="isWaitDownload" >{{$t("fatture_pay.invoice_warn")}}</b-alert>
            <b-alert  variant="warning" id="errore_waiting" v-model="isWait" >Attendere, verifica pagamento in corso</b-alert>
            <b-alert  variant="danger" id="errore_danger" v-model="isErr" >{{ this.messaggio}}</b-alert>
            <b-alert  variant="warning" id="errore_warning" v-model="isWar" >{{ this.messaggio}}</b-alert>
            <b-alert  variant="danger" id="info-fatture" v-model="isFat" >{{$t("fatture_pay.invoice")}}</b-alert>

          </div>
        </div>

        <div class="row no-gutters">
          <div class="col-12 offset-0">

            <app-fat-list v-if="this.fatture.length>0" :selectTypeLine="true" :fatture="this.fatture"  :is-pay="true" :showDownload="this.showDownload" ></app-fat-list>

            <div class="o-ref-list__topline">
              <div class="o-ref-list__inner">
                <b-button v-if="codTrans===null && !showDownload &&!isFat"
                          variant="outline-primary"
                          class="text-uppercase mt-2 ml-xl-2"
                          :to="{ path: this.link==null?'/':this.link   }"
                >{{ $t("fatture_pay.cancel_btn") }}</b-button>
                <b-button v-if="showDownload"
                          variant="outline-primary"
                          class="text-uppercase mt-2 ml-xl-2"
                          :to="{ path: '/referti'   }"
                >{{ $t("fatture_pay.back") }}</b-button>
                <b-button v-if="this.somma>0 && this.codTrans===null && !showDownload && !isFat"
                          variant="secondary"
                          class="text-light text-uppercase mt-2 ml-xl-2"

                          @click="inviaPagamento"
                >{{ $t("fatture_pay.pay") }} {{this.somma | toCurrency}}
                </b-button>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
    <b-modal ref="fattura-paid"
             id="fattura-paid"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="false"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-refresh"></span>
      </div>
      <p class="o-modal__body--title text-warning">
        <strong> Attenzione </strong>
      </p>
      <p>
        Una fattura risulta pagata o in pagamento, ricaricare la pagina Fatture
      </p>
      <!--<b-button v-if="!jit" variant="primary" size="" class="text-light text-uppercase mt-3"  @click="queryReferto"
      >RICHIEDI PUBBLICAZIONE</b-button
      >-->
      <b-button variant="outline-primary" size="" class="text-uppercase mt-3"  @click="closePaid"
      >OK</b-button
      >

    </b-modal>

  </div>
</template>
<script>

import AppFatList from "../components/organisms/AppFatList.vue";
import apiAxios from "@/libs/apiAxios";
import router from "@/router";
//import apiAxios from "@/libs/apiAxios";
export default {
  components: {  AppFatList },
  name: "fatture",

  created() {
    this.getData();
  },
  data() {
    return {
      loading:true,
      in_fatture:[],
      err:null,
      esito:null,
      messaggio:null,
      link:'',
      selctable:false,
      row: false,
      isErr:false,
      isWar:false,
      isWait:false,
      isFat:false,
      isWaitDownload:false,
      pollInterval:null,
      codTrans: null,
      selected: "a",
      showDownload: false,
      pollingPayment:true,
      options: [
        /*      { value: "a", text: "Giacomo Magnani" },
              { value: "b", text: "Mario Rossi" },*/
      ],
    };
  },

  computed: {
    fatture(){
      return this.in_fatture;
    },
    somma(){
      let sum=0;
      for(let i=0;i<this.in_fatture.length;i++)
      {
        if(this.in_fatture[i]/*.selected*/)
        {
          sum+=this.in_fatture[i].ImportoDocumento;
        }
      }

      return sum;
    }

  },

  methods: {

    closePaid(){
      this.$refs["fattura-paid"].hide();
      router.push("/fatture");
    },
    updateRow(e) {
      this.row = e;
    },
    getData() {
      this.loading = true
      let store=null;

      let idPar=null;


      let uri = window.location.href.split('?');
      if (uri.length == 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function (v) {
          tmp = v.split('=');
          if (tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });

        if ('codTrans' in getVars)
        {
          idPar=getVars.codTrans;
        }

        if ('esito' in getVars)
        {
          this.esito=getVars.esito;
        }

        if ('err' in getVars)
        {
          this.err=getVars.err;
        }

        if ('messaggio' in getVars)
        {
          this.messaggio=decodeURI( getVars.messaggio);
        }
      }



      if (idPar!=null)
      {
        let tmp=sessionStorage.getItem('tran_'+idPar);
        sessionStorage.setItem('invoices',tmp);
        sessionStorage.removeItem('tran_'+idPar);
      }

      store=sessionStorage.getItem('invoices');
      if (store!=null)
      {
        const dati =JSON.parse(store);
        this.selctable=dati.selctable;
        this.link=dati.source;
        this.in_fatture=JSON.parse(atob(dati.list));
        console.log(this.in_fatture);
      }
      else {
        this.link=null;
        this.in_fatture=[];
      }


      this.loading=false;
      if (this.esito===null)
        return;
      if (this.esito==="OK")
      {
        if (idPar!=null)
        {

          this.isWait=true;
          this.codTrans=idPar;
          this.checkPayment();
          return;

        }

      }
      else {
        let arr=[];

        for(let i=0;i<this.in_fatture.length;i++)
        {
          arr.push(this.in_fatture[i].NumeroDocumento);
        }
        const data={fatture:arr};
        apiAxios.post("jit/fatture/cancelPendingPayment",data);
        if (this.esito === "KO") {
          this.isErr = true;
          this.messaggio = this.$t("fatture_pay.fail");

        }
        if (this.esito === "ANNULLO") {
          this.isWar = true;
          this.messaggio =this.$t("fatture_pay.cancel");
        }
      }
    },
    polling:function()
    {
      if (this.$route.path!=='/fattureDaPagare')
      {
        console.log("Polling DIE:"+this.$route.path);
        clearInterval(this.pollInterval);
        return;
      }

      if(this.pollingPayment) {
        this.checkPayment();
      }
      else {
        this.reloadUser();
      }

    },
    showFatture()
    {

    },

    async innerReloadUser()
    {
      if (sessionStorage.getItem("singolo")!=="true") {
        try {
          await this.$store.dispatch("dateUser/getUser").then(() => {
            router.push(this.link);
          });
        } catch (e) {
          console.log({message: "Some error getUser"});
        }
      }
    },

    async reloadUser() {


        let _this=this;
        let xxx=this.in_fatture;
        let data=[];
        for (let i=0;i<xxx.length;i++)
        {
          data.push({fatt: xxx[i].NumeroDocumento});
        }
        this.isFat=true;
        apiAxios.post("jit/confermaIncassi", {invoices:data})
            .then(response=>{
              const data=response.data.ConfermaIncassiResult;
              for(let i=0;i<data.length;i++)
              {
                if (!data[i].incasso) {
                  _this.pollingPayment=false;
                  _this.isFat=true;
                  return;
                }
              }
              _this.showDownload=true;
              _this.pollingPayment=true;
              _this.isFat=false;
              if (sessionStorage.getItem("singolo")==="true")
                _this.isWaitDownload=true;

              _this.innerReloadUser();

            });

       // router.push("referti");
        return;


    },


    checkPayment()
    {
      if (this.codTrans===null)
        return;
      const data={codTrans:this.codTrans}

      apiAxios.post("jit/fatture/pollPendingPayment",data)
          .then(response=>{
            const val=response.data.PollPendingPaymentResult;
            if (val===null)
              return;
            if (val)
            {

              this.codTrans=null;
              this.isWait=false;
              this.reloadUser();
              return;
            }
            else {
              this.codTrans=null;
              this.isWait=false;
              this.isErr=true;
              this.messaggio="Errore verifica pagamento";
            }
          })
          .catch(err=>{
            console.log(err);
            this.isErr=true;
            this.messaggio="Errore verifica pagamento";
          })

    },
    inviaPagamento() {
      let fatts=[];
      for (let i=0;i<this.in_fatture.length;i++)
      {
        if (this.in_fatture[i].selected)
        {
          fatts.push(this.in_fatture[i]);
        }
      }

      let url=window.location+'';
      url=url.split('?');


      const data={
        importo:this.somma,
        payload:fatts,
        url:url[0]
      }

      apiAxios.post("jit/fatture/getPaylink",data)
          .then(response=>{

            const data=response.data.getPaylinkResult;
            if (data.codTrans!=null)
            {
              const tmp=sessionStorage.getItem("invoices");
              sessionStorage.setItem("tran_"+data.codTrans,tmp);
              sessionStorage.removeItem('invoices');
              window.location=data.url;
            }
            else {
              if ('error' in data)
              {
                sessionStorage.removeItem('invoices');
                this.$refs["fattura-paid"].show();
              }
            }
          })
          .catch(errore=>{console.log(errore)})
    }


  },
  mounted() {


    if(!this.pollDone ) {
      this.pollInterval = setInterval(this.polling, 5000) //save reference to the interval
      setTimeout(() => {clearInterval(this.pollInterval)}, 12000000) //stop polling after an hour
    }

  }

};
/*   ,
   updateRow(e) {
     this.row = e;
   },*/


</script>
